import React from "react";
import { Draggable } from "react-beautiful-dnd";

const Tickets = ({tickets}) => {
  return (
    <>
      {tickets.map((ticket, index) => (
        <Draggable key={ticket.id} draggableId={ticket.id.toString()} index={index}>
          {(provided) => (
            <div ref={provided.innerRef}
                     {...provided.draggableProps}
                     {...provided.dragHandleProps}
            >
              <div className="m-[12px] bg-white	rounded-[8px] text-left	p-[12px]">
                <h4>Name: {ticket.name}</h4>
                <p>Description: {ticket.description}</p>
              </div>
            </div>
          )}
        </Draggable>
      ))}
    </>
  )
}

export default Tickets
