import React from "react";
import finalImage from '../assets/final-image.png'

const ComingSoon = () => {
  return (
    <>
      <div className="flex justify-center items-center h-screen text-gray-800 flex-col bg-black">
        <img src={finalImage} alt="Final Image" height="200px" width="200px" className="rounded-full" />
        <div className="text-center">
          <h1 className="text-[50px] font-bold animate-pulse text-white">Coming Soon</h1>
          <p className="mt-4 text-lg text-white">We're working on something awesome! Stay tuned.</p>
        </div>
      </div>
    </>
  );
}

export default ComingSoon
