import React from "react";
import Tickets from "./Tickets";
import { Droppable } from 'react-beautiful-dnd';

const BoardColumns = ({ onOpenModal, boardColumns }) => {
  return (
    <>
      {boardColumns.map((boardColumn) => (
        <Droppable droppableId={boardColumn.id.toString()} key={boardColumn.id} >
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef} >
              <div className="w-80 border text-center bg-sky-100 rounded-[12px] pb-[16px]" key={boardColumn.id} >
                <h3 className="text-2xl pt-[8px] pb-[8px] font-bold capitalize">{boardColumn.name}</h3>
                <Tickets tickets={boardColumn.tickets} />
                <button className="rounded p-1.5 bg-cyan-500	font-extrabold" onClick={() => {onOpenModal(boardColumn.id)}}>Add Ticket</button>
              </div>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      ))}
    </>
  )
}

export default BoardColumns
