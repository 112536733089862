import React, { useState, useEffect } from "react";
import axios from "axios";
import TicketModal from "./TicketModal";
import BoardColumns from "./BoardColumns";
import { DragDropContext } from 'react-beautiful-dnd';
import instance from "../api-client";

function Board() {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [firstBoard, setFirstBoard] = useState(null)
  const [boardColumns, setBoardColumns] = useState([])
  const [boardColumnId, setBoardColumnId] = useState(null)

  const openModal = (boardColumnId) => {
    setIsModalOpen(true);
    setBoardColumnId(boardColumnId)
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    instance.get(`boards/1`)
         .then(res => {
          setFirstBoard(res.data)
         })
         .catch(error => console.log(error));
  },[])

  useEffect(() => {
    instance.get(`boards/1/board_columns`)
      .then(res => {
        setBoardColumns(res.data)
      })
      .catch(error => console.log(error));
  },[])

  function handleSubmitTicket(boardColumnId, nameTicket, descriptionTicket) {
    instance.post(`boards/1/board_columns/${boardColumnId}/tickets`,
          { name: nameTicket, description: descriptionTicket })
    .then(res => {
      const getBoardColumn = boardColumns.filter((boardColumn) => boardColumn.id === boardColumnId )
      const getIndex = boardColumns.findIndex((boardColumn) => boardColumn.id === boardColumnId)
      const setTicketToBoard = [...getBoardColumn[0].tickets, res.data]
      boardColumns[getIndex].tickets = setTicketToBoard
      setBoardColumns(boardColumns)
      setIsModalOpen(false);
    })
    .catch(error => console.log(error));
  }

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const destinationColumn = boardColumns.find((boardColumn) => boardColumn.id === parseInt(destination.droppableId))
    const sourceColumn = boardColumns.find((boardColumn) => boardColumn.id === parseInt(source.droppableId))
    const currentTicket = sourceColumn.tickets.find((ticket) => ticket.id === parseInt(draggableId))
    const newSourceTicket = sourceColumn.tickets.filter((ticket) => ticket.id !== currentTicket.id)
    destinationColumn.tickets.splice(destination.index, 0, currentTicket)
    sourceColumn.tickets = newSourceTicket

    if (destination.droppableId === source.droppableId) {
      destinationColumn.tickets.splice(destination.index, 0, currentTicket)
    }

    instance.put(`boards/1/board_columns/update_drag_drop`,
          { ticket_id: draggableId, source_colum: sourceColumn.id, destination_column: destinationColumn.id,
            source_tickets: sourceColumn.tickets.map((ticket, index) => ({ id: ticket.id, order_id: index })), 
            destination_tickets: destinationColumn.tickets.map((ticket, index) => ({ id: ticket.id, order_id: index }))})
         .then(res => {
            if (res.status === 200) {
              setBoardColumns(boardColumns)
            }
         })
  };

  return (
    <>
      <h1 className="text-4xl font-semibold	pl-[12px] pt-[8px] pb-[8px] border bg-slate-200">
        {firstBoard ? firstBoard.name : "not found"}
      </h1>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="flex mt-[18px] gap-[8px] overflow-auto flex-1">
          { boardColumns.length > 0 ? <BoardColumns boardColumns={boardColumns} onOpenModal={openModal}/> : <h3>loading ...</h3> }
        </div>
      </DragDropContext>
      <TicketModal isOpen={isModalOpen} onClose={closeModal} handleSubmitTicket={handleSubmitTicket} boardColumnId={boardColumnId}/>
    </>
  )
}

export default Board
