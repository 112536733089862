import './App.css';
import Board from './components/Board';
import Weather from './components/Weather';
import ComingSoon from './components/CommingSoon';
import { Route, Routes } from 'react-router-dom';


function App() {
  return (
    <>
      <Weather backgroudColor={"bg-black"} textColor={"text-white"} />
      <Routes>
        <Route path="/" element={<ComingSoon />} />
        <Route path="/boards/1" element={<Board />} />
        <Route path="*" element={<ComingSoon />} />
      </Routes>
    </>
  )
}

export default App;
