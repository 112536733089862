import React, { useEffect, useState } from "react";
import axios from "axios";

const Weather = ({backgroudColor, textColor}) => {
  const [latitude, setLatitude] = useState('')
  const [longitude, setLongitude] = useState('')
  const [temperature, setTemperature] = useState('')

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setLatitude(position.coords.latitude)
      setLongitude(position.coords.longitude)
    });
  },[])

  useEffect(() => {
    if (latitude !== "" && longitude !== "") {
      axios.get(`https://api.open-meteo.com/v1/forecast?latitude=${latitude}&longitude=${longitude}&current=temperature_2m`)
      .then(res => {
        setTemperature(res.data)
      })
      .catch(error => console.log(error));
    }
  },[latitude, longitude])

  return (
    <>
      <h1 className={`text-center font-semibold flex justify-center w-full text-6xl absolute pt-[20px] pb-[12px] pl-[12px] pr-[12px] ${textColor} ${backgroudColor}`}>
        The current temperature is: {temperature ? temperature.current.temperature_2m + "°C" : "loading..." }
      </h1>
    </>
  )
}

export default Weather
