import React, { useState } from 'react';

const TicketModal = ({ isOpen, onClose, handleSubmitTicket, boardColumnId }) => {
  const [nameTicket, setNameTicket] = useState('');
  const [descriptionTicket, setDescriptionTicket] = useState('');

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center" >
      <div className='fixed inset-0 z-10 bg-gray-900 bg-opacity-50 backdrop' onClick={() => onClose()}></div>
      <div className="bg-white p-6 rounded-lg w-96 z-10 relative">
        <div className='flex justify-between'>
          <h2 className="text-xl font-bold mb-4">Add Ticket</h2>
          <svg width="20px" height="20px" viewBox="0 0 16 16" version="1.1" className='absolute top-3 right-3 cursor-pointer' onClick={() => onClose()}>
            <rect width="16" height="16" id="icon-bound" fill="none" />
            <polygon points="14.707,2.707 13.293,1.293 8,6.586 2.707,1.293 1.293,2.707 6.586,8 1.293,13.293 2.707,14.707 8,9.414 
            13.293,14.707 14.707,13.293 9.414,8 "/>
          </svg>
        </div>
        <div>
          <label htmlFor="nameTicket" className="block text-sm font-medium text-gray-700">
            Name
          </label>
          <input
            type="text"
            id="nameTicket"
            className="border border-gray-300 rounded-md px-3 py-2 mt-1 block w-full"
            value={nameTicket}
            onChange={(e) => setNameTicket(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="descriptionTicket" className="block text-sm font-medium text-gray-700">
            Description
          </label>
          <input
            type="text"
            id="descriptionTicket"
            className="border border-gray-300 rounded-md px-3 py-2 mt-1 block w-full"
            value={descriptionTicket}
            onChange={(e) => setDescriptionTicket(e.target.value)}
            required
          />
        </div>
        <div className="text-right pt-[8px]">
          <button
            type="submit"
            className="bg-cyan-500 text-white py-2 px-4 rounded-md hover:bg-cyan-600 focus:outline-none focus:ring-2 focus:ring-cyan-500"
            onClick={() => {handleSubmitTicket(boardColumnId, nameTicket, descriptionTicket)}}
          >
            Add
          </button>
        </div>
      </div>
    </div>
  );
};

export default TicketModal;
